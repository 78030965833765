<template>
<div class="content">
	<div class="hero-image" v-if="content && content.heroImage">
		<DatocmsImage v-if="content.heroImage" :data="content.heroImage.responsiveImage" />
		<h1>{{content.title}}</h1>
		<div class="hero-actions">
			<div class="inner">
				<div><nuxt-link to="/sell-your-tesla" class="btn-primary">Sell Your Tesla</nuxt-link></div>
				<div><nuxt-link to="/inventory" class="btn-primary">Browse Inventory</nuxt-link></div>
			</div>
		</div>
	</div>
	<div class="red-bar">
		Use our referral link <B>jeffrey51305</B> to get discounts on a new Tesla purchase!
		<a href="https://ts.la/jeffrey51305" class="btn-secondary">Go</a>
	</div>
	<inventory :initialcars="cars" :initialfeatureoptions="feature_options"></inventory>
</div>
</template>
<script>
import config from '@/config';
import { Image,StructuredText } from 'vue-datocms';
import _ from 'lodash';
import Inventory from '@/components/Inventory';
import axios from 'axios';
export default {
	components: {
		DatocmsImage: Image,
		DatocmsStructuredText: StructuredText,
		inventory: Inventory
	},
	data() {
		return {
			content: [],
			cars: [],
			feature_options: [],
		}
	},
	head() {
		if (!this || !this.content) {
			return;
		}
		return {
			title: "PRE-USED EV - "+this.content.metaTags?.title,
			meta: [
			{
				hid: 'description',
				name: 'description',
				content: this.content.metaTags?.description
			}
			],
			bodyAttrs: {
				class: "content-page home-page"
			}
		}
    },
	methods: {
	},
	props: {
	},
	watch: {
	},
	async mounted(){


	},
	async asyncData({ error, params, $axios, store }) {
		const response = await fetch('https://graphql.datocms.com/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: "Bearer 1a38e85c5b3ab3f6e89d0f1ff3360e",
			},
			body: JSON.stringify({
				query: `
					query getPost {
						allPosts(filter: {slug: {eq: "home-page"}}) {
							slug
							title
							content
							heroImage {
								responsiveImage(imgixParams: { fit: crop, ar: "16:9", w: 750 }){
									aspectRatio
									height
									sizes
									src
									srcSet
									webpSrcSet
									width
									alt
									title
								}
							}
							_seoMetaTags {
							tag
							content
							attributes
							}
							metaTags {
							description
							title
							twitterCard
							}
						}
					}

				`
			})
		})
		const { data: contentData } = await response.json()

		let formData = {
			action: "query",
			sort: "date_added",
			model: store.state.filters.model,
			sold: store.state.filters.sold,
			filter: store.state.filters.filter,
			vin: store.state.filters.vin,
			status: store.state.filters.status,
		};

		const encodeForm = (data) => {
			return Object.keys(data)
				.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
				.join('&');
		}

		var queryString = encodeForm(formData);
		store.state.filters.features.map(function(value, key){
			queryString += "&features[]="+ encodeURIComponent(value.name);
		});

		const [carsRes, featuresRes] = await Promise.all([
    		$axios.post(config.API_URL+"?"+queryString, queryString),
    		$axios.post(config.API_URL+"?action=getFeatures")
		]);

		return {
			content: contentData?.allPosts[0],
			cars: carsRes.data.data || [],
			feature_options: featuresRes.data.data
		}
	}
}
</script>
<style lang="scss">

</style>
